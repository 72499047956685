.fullscreen-carousel-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 600px;
  position: relative;
  overflow: hidden;
}

.carousel-item-details h4 {
  padding-bottom: 12px;
  padding-top: 8px;
}

.carousel-read-more {
  padding-bottom: 12px;
}

.carousel-item-details {
  padding-bottom: 12px;
  padding-top: 16px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-left: 10%;
  padding-right: 10%;
  color: white;
}

@media only screen and (min-width: 900px) {
.carousel-image-container {
  width: 100%;
  height: 100%;
}
}

@media only screen and (max-width: 1200px) {
    .fullscreen-carousel-container {
        height: 500px;
    }

    .carousel-item-details {
      padding-left: 32px;
      padding-right: 32px;
    }

    .fullscreen-carousel-frame image {
      width: 200px;
    }
  }

  @media only screen and (max-width: 350px) {
    .fullscreen-carousel-container {
        height: 500px;
    }

    .carousel-item-details {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

.fullscreen-carousel-frame {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: opacity 1s ease-in-out;
}

.fullscreen-carousel-frame.active {
  opacity: 1;
}

.fullscreen-carousel-frame img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.fullscreen-carousel-frame.active img {
  animation: fade-in 1s ease-in-out;
}

.fullscreen-carousel-controls {
  position: relative;
  top: 50%;
  padding-bottom: 12px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transform: translateY(-50%);
}

.fullscreen-carousel-controls button {
  margin: 0 10px;
}
