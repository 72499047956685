.contact-us-main {
  background-color: #200063;
}

.contact-input-box {
  width: 90%;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #fafafa;
  background-color: #200063;
  border-radius: 10px;
}

.contact-form-container {
  width: 90%;
  margin: auto;
  padding-left: 2rem;
  padding-right: 2rem;
  color: #fafafa;
  background-color: #200063;
  border-radius: 10px;
}

.contact-form-container h2 {
  text-align: center;
  margin-bottom: 1rem;
}

.contact-form-container form {
  display: grid;
  gap: 1rem;
}

.contact-form-container label {
  font-size: 1.2rem;
  font-weight: bold;
}

.contact-form-container input,
.contact-form-container textarea {
  padding: 0.5rem;
  font-size: 1.2rem;
  background-color: #efefef;
  border: 2px solid #200063;
  border-radius: 5px;
}

.contact-form-container input {
  color: gray;
}

.submit-button-container {
  display: flex;
  justify-content: center;
  padding-bottom: 64px; /* Add this line */
}

.submit-button {
  background-color: #0d99ff;
  color: #efefef;
  border: none;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 1.2rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
}

.submit-button:hover {
  background-color: #0069d9;
}

.submit-button-container {
  display: flex;
  justify-content: center;
}

.status-message-container {
  display: flex;
  justify-content: center;
  height: 0px; /* Set a fixed height for the status message container */
}

.status-message {
  font-weight: bold;
}

.contact-form-container textarea {
  padding: 0.5rem;
  font-size: 1.2rem;
  background-color: #efefef;
  border: 2px solid #200063;
  border-radius: 5px;
  color: gray; /* Update this line */
}

.status-message.success {
  color: green;
}

.status-message.error {
  color: red;
}

@media screen and (max-width: 768px) {
  .contact-form-container {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }

  .contact-container {
    background-color: #200063;
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
