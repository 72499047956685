  .framework-accordion-framework-container {
    padding-top: 4px;
    padding-bottom: 4px;
    background-color: #200063;
  }

  .accordion-wrk-container {
    padding-top: 64px;
    padding-bottom: 64px;
    background-color: #200063;
  }

  @media screen and (max-width: 700px) {
    .accordion-wrk-container {
      padding-top: 32px;
      padding-bottom: 32px;
      background-color: #200063;
    }
  }
  