/* styles.css */

.btn {
  font-family: "Segoe UI", sans-serif;
  font-weight:700;
  border-radius: 4px;
  cursor: pointer;
  border: none;
  outline: none;
  transition: background-color 0.3s ease-in-out;
  text-transform: capitalize;
}

.btn-small {
  font-size: 0.8rem;
  padding: 8px 16px;
}

.btn-medium {
  font-size: 1rem;
  padding: 12px 24px;
}

.btn-large {
  font-size: 1.2rem;
  padding: 16px 32px;
}


.btn:hover {
  filter: brightness(85%);
}

h1 {
  font-size: 56px;
  font-weight:400;
  line-height: 1.2;
}

h2 {
  font-size: 36px;
  font-weight:400;
  line-height: 1.5;
}

h3 {
  font-size: 28px;
  font-weight:350;
  line-height: 1.5;
}

h4 {
  font-size: 20px;
  font-weight:400;
  line-height: 1.5;
}

h5 {
  font-size: 16px;
  font-weight:400;
  line-height: 1.5;
}

h6 {
  font-size: 14px;
  font-weight:700;
  line-height: 1.5;
}

body {
  font-size: 16px;
  font-weight:400;
  line-height: 1.5;
}

@media only screen and (max-width: 1024px) {
  h1 {
    font-size: 42px;
    font-weight:400;
    line-height: 1.2;
  }

  h2 {
    font-size: 24px;
    font-weight:400;
    line-height: 1.5;
  }

  h3 {
    font-size: 22px;
    font-weight:350;
    line-height: 1.5;
  }

  h4 {
    font-size: 14px;
    font-weight:400;
    line-height: 1.5;
  }

  h5 {
    font-size: 14px;
    font-weight:400;
    line-height: 1.5;
  }

  h6 {
    font-size: 12px;
    font-weight:700;
    line-height: 1.5;
  }
}
  