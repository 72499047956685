.newsletter-background {
  background-color: #200063; 
  display: flex;
  justify-content: center;
  padding-bottom: 64px;
  padding-top: 32px;
  max-width: 100%;
}

.error-message {
  padding-top: 8px;
  color: red;
}

.success-message {
  padding-top: 8px;
  color: #A6F005;
}

.subscribe-input-box {
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Add this line */
  margin-bottom: 16px;
  padding-bottom: 24px;
}

.newsletter-container {
  display: grid;
  justify-content: center;
  grid-template-columns: 50% 50%;
  justify-items: center;
  margin-top: 32px;
}

.newsletter-details-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-right: 128px;
}

.newsletter-title {
  font-weight: bold;
  margin-top: 8px;
  margin-bottom: 16px;
  color: #ffffff;
  font-size: 14px;
}

.newsletter-text {
  margin-bottom: 24px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center; /* Add this line */
}

.newsletter-form {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center; /* Add this line */
}

.newsletter-form input {
  padding: 8px;
  border-radius: 4px;
  border: none;
  width: 300px;
}

.newsletter-form button:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

@media (max-width: 860px) {
  .newsletter-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .newsletter-background {
    max-width: 860px;
  }

  .newsletter-details-container,
  .newsletter-submit-button {
    display: flex;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 450px) {
  .newsletter-image {
    max-width: 200px;
  }

  .newsletter-image-container,
  .newsletter-details-container,
  .newsletter-submit-button {
    display: flex;
    padding-left: 32px;
    padding-right: 32px;
    justify-content: center;
    align-items: center;
  }

  .newsletter-container {
    grid-template-columns: 1fr;
    grid-template-rows: auto auto;
  }

  .newsletter-form input {
    width: 230px;
  }
}
