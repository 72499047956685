.image-right-text-left-container-top {
  display: flex;
  align-items: center;
  padding-top: 64px;
}

.image-right-text-left-image-top {
  max-width: 100%;
  min-height: 700px;
  object-fit: cover;
  align-self: flex-start; 
}

.image-right-text-left-content-top {
padding-left: 128px;
padding-right: 0px;
}

.image-right-text-left-content-top h1 {
  font-size: 102px;
}

.image-right-text-left-left-top,
.image-right-text-left-right-top {
  flex: 1;
}

.body-image-right-top-section {
  padding-left: 128px;
  padding-right: 128px;
}

@media only screen and (max-width: 1850px) {
  .image-right-text-left-container-top {
    padding-left: 64px;
  }

  .image-right-text-left-image-top {
    max-width: 100%;
    min-height: 500px;
    object-fit: cover;
    align-self: flex-start; 
    padding-right: 100px;
  }

  .image-right-text-left-container-top {
    padding-left: 128px;
  }
  
  .body-image-right-top-section {
    padding-left: 128px;
    padding-right: 128px;
  }
  
  .image-right-text-left-content-top {
  padding-left: 64px;
  }
  
  .image-right-text-left-content-top h1 {
    font-size: 102px;
  }
}

@media only screen and (max-width: 1400px) {
  .image-right-text-left-container-top {
    padding-left: 32px;
  }

  .image-right-text-left-image-top {
    max-width: 100%;
    min-height: 400px;
    object-fit: cover;
    align-self: flex-start; 
    padding-right: 16px;
  }

  .image-right-text-left-container-top {
    padding-left: 32px;
  }
  
  .body-image-right-top-section {
    padding-left: 96px;
    padding-right: 96px;
  }
  
  .image-right-text-left-content-top {
  padding-left: 64px;
  }
  
  .image-right-text-left-content-top h1 {
    font-size: 82px;
  }
}

@media only screen and (max-width: 950px) {
  .image-right-text-left-container-top {
    padding-left: 32px;
  }

  .image-right-text-left-image-top {
    max-width: 100%;
    min-height: 400px;
    object-fit: cover;
    align-self: flex-start; 
    padding-right: 16px;
  }

  .image-right-text-left-container-top {
    padding-left: 0px;
    padding-top: 64px;
  }
  
  .body-image-right-top-section {
    padding-left: 64px;
    padding-right: 64px;
  }
  
  .image-right-text-left-content-top {
  padding-left: 0px;
  }
  
  .image-right-text-left-content-top h1 {
    font-size: 82px;
  }

  .image-right-text-left-container-top {
    display: flex;
    flex-direction: column; /* Modified property */
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-content-top h1 {
    font-size: 62px;
  }

  .image-right-text-left-right-top {
    order: 0; /* New property */
  }

  .image-right-text-left-left-top {
    padding-right: 1; /* Modified property */
  }

  .image-right-text-left-image-top {
    max-width: 100%;
    min-height: 450px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 700px) {
  .image-right-text-left-container-top {
    padding-left: 0px;
  }

  .image-right-text-left-image-top {
    min-height: 300px;
    object-fit: cover;
    align-self: flex-start; 
    padding-right: 0px;
  }

  .image-right-text-left-container-top {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .body-image-right-top-section {
    padding-left: 32px;
    padding-right: 32px;
  }
  
  .image-right-text-left-content-top {
  padding-left: 16px;
  padding-right: 16px;
  }
  
  .image-right-text-left-content-top h1 {
    font-size: 48px;
  }

  .image-right-text-left-container-top {
    display: flex;
    flex-direction: column; /* Modified property */
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-right-top {
    order: 0; /* New property */
  }

  .image-right-text-left-left-top {
    padding-right: 1; /* Modified property */
  }

  .image-right-text-left-image-top {
    max-width: 100%;
    min-height: 450px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 500px) {
  .image-right-text-left-container-top {
    padding-left: 0px;
  }

  .image-right-text-left-image-top {
    min-height: 220px;
    object-fit: cover;
    align-self: flex-start; 
    padding-right: 0px;
  }

  .image-right-text-left-container-top {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .body-image-right-top-section {
    padding-left: 16px;
    padding-right: 16px;
  }
  
  .image-right-text-left-content-top {
  padding-left: 24px;
  padding-right: 24px;
  }
  
  .image-right-text-left-content-top h1 {
    font-size: 32px;
  }

  .image-right-text-left-container-top {
    display: flex;
    flex-direction: column; /* Modified property */
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-right-top {
    order: 0; /* New property */
    padding-left: 16px;
  }

  .image-right-text-left-left-top {
    padding-right: 1; /* Modified property */
  }
}