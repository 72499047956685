.framework-hero-container {
  display: flex;
  align-items: center; 
  padding-right: 256px;
  padding-top: 64px;
}

.framework-hero-content {
  padding-bottom: 16px;
}

.framework-hero-left, .framework-hero-right {
  flex: 1; 
}

.framework-hero-right-padding {
  padding-bottom: 64px;
}

.framework-hero-right {
  padding-left: 16px;
  padding-top: 64px;
}

.framework-hero-left img {
  max-width: 100%;
  min-height: 400px; 
  object-fit: cover; 
}

@media only screen and (max-width: 1400px) {
  .framework-hero-container {
    display: flex;
    align-items: center; 
    padding-right: 64px;
    padding-top: 64px;
  }

  .framework-hero-left img {
    max-width: 100%;
    min-height: 350px; 
    object-fit: cover; 
  }
}

@media only screen and (max-width: 1250px) {
  .framework-hero-container {
    padding-top: 60px;
    display: flex;
    align-items: center; 
    padding-right: 32px;
    padding-top: 64px;
  }

  .framework-hero-right {
    padding-left: 8px;
  }
}

@media only screen and (max-width: 800px) {
  .framework-hero-container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-right: 32px;
  }

  .framework-hero-right-padding {
    padding-bottom: 16px;
  }

  .framework-hero-right {
    order: -10;
    padding-top: 16px;
    padding-left: 32px;
  }

  .framework-hero-left img {
    max-width: 100%;
    min-height: 300px; 
    object-fit: cover; 
  }
}

@media only screen and (max-width: 450px) {
  .framework-hero-container {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-right: 0px;
    padding-top: 64px;
  }

  .framework-hero-content h1 {
    font-size: 32px;
    padding-left: 12px;
    padding-right: 12px;
  }

  .framework-hero-right-padding {
    padding-bottom: 16px;
  }

  .framework-hero-right {
    order: -10;
    padding-top: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .framework-hero-left img {
    max-width: 100%;
    min-height: 250px; 
    object-fit: cover; 
  }
}