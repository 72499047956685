
.hero-section {
  background-size: cover;
  background-position: center;
  height: 120vh; /* adjust the height to your desired size */
  display: flex;
  align-items: center;
  justify-content: center;
}

.left-content {
  align-items: flex-start; /* Align the image to the left */
}

.left-content img {
  max-width: 920px; /* Set the image width to 900px */
  max-width: 100%;
  height: 120vh;
  padding-top: 60px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-45%);
  z-index: 1;
}

.hero-content {
  align-items: center; /* Change to center to align right content in the center */
}

.right-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* Add or modify these rules in your HeroSection.css file */

.right-text {
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translateY(-50%) translateX(-50%);
  text-align: center;
  color: #fff; /* Set the text color as needed */
  z-index: 2;
}

.right-text {
  margin-bottom: 32px; /* Adjust the value as needed */
}

.right-text h1 {
  margin: 48px;
  padding: 0;
  line-height: 1.2;
}

.right-text h3 {
  margin: 24px;
  padding: 0;
  line-height: 1.2;
}

.right-text button {
  background-color: #0d99ff; /* Set the button background color as needed */
  color: #fff; /* Set the button text color as needed */
  border: none;
  padding: 12px 24px;
  margin-top: 20px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.right-text button:hover {
  background-color: #0056b3; /* Set the button hover background color as needed */
}

.my-button:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.right-content img {
  max-width: 100%;
  height: 700px;
  z-index: 1; 
  position: absolute;
  top: 50%;
  left: 65%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 2;
}

.right-content {
  order: -1; /* Move the right content above the left content */
}

/* Add a media query for smaller screens */
@media (max-width: 1400px) {
  .hero-section {
    height: 45rem;
  }
  
  .right-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: bottom;
    justify-content: bottom;
  }
  
  .left-content img {
    height: 46rem;
    top: 30%;
    left: 0;
    transform: translateY(-50%);
  }
  
  /* Add or modify these rules in your HeroSection.css file */
  
  .right-text {
    position: absolute;
    top: 35%;
    left: 65%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    color: #fff; /* Set the text color as needed */
    z-index: 2;
    margin: 0px;
  }
  
  .right-text {
    margin-bottom: 32px; /* Adjust the value as needed */
  }
  
  .right-text h1 {
    margin: 24px;
    padding: 0;
    font-size: 32px;
    line-height: 1.2;
  }
  
  .right-text h3 {
    font-size: 18px;
    padding: 0px;
    line-height: 1.2;
    margin: 0px;
  }
  
  .right-text button {
    background-color: #0d99ff; /* Set the button background color as needed */
    color: #fff; /* Set the button text color as needed */
    border: none;
    padding: 12px 24px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .right-text button:hover {
    background-color: #0056b3; /* Set the button hover background color as needed */
  }
  
  .right-content img {
    max-width: 100%;
    height: 500px;
    z-index: 1; 
    position: absolute;
    top: 35%;
    left: 65%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }  
}

@media (max-width: 800px) {
  .right-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: bottom;
  }
  
  .right-text {
    position: absolute;
    top: 40%;
    left: 65%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    color: #fff; /* Set the text color as needed */
    z-index: 2;
    width: 380px;
    margin-bottom: 32px;
  }
  
  .right-text h1 {
    margin: 20px;
    padding: 0;
    font-size: 32px;
    line-height: 1.2;
  }
  
  .right-text h3 {
    margin: 12px;
    font-size: 18px;
    padding: 0;
    line-height: 1.2;
  }
  
  .right-text button {
    background-color: #0d99ff; /* Set the button background color as needed */
    color: #fff; /* Set the button text color as needed */
    border: none;
    padding: 12px 24px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .right-text button:hover {
    background-color: #0056b3; /* Set the button hover background color as needed */
  }
  
  
  .right-content img {
    max-width: 100%;
    height: 350px;
    z-index: 1; 
    position: center;
    top: 40%;
    left: 65%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }  

  .hero-section {
    background-size: cover;
    background-position: center;
    height: 40rem; /* adjust the height to your desired size */
    display: flex;
    align-items: center;
  }

  .left-content {
    align-items: flex-start; /* Align the image to the left */
  }
  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .left-content img {
    position: absolute;
    top: 0;
    width: 500px;
    height: auto;
    transform: translateY(0%);
    z-index: 1; /* Make the left image appear below the right image */
  }
}

@media (max-width: 600px) {
  .right-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: right;
    justify-content: bottom;
  }
  
  .right-text {
    position: absolute;
    top: 44%;
    left: 60%;
    transform: translateY(-50%) translateX(-50%);
    text-align: center;
    color: #fff; /* Set the text color as needed */
    z-index: 2;
    width: 380px;
    margin-bottom: 32px;
  }
  
  .right-text h1 {
    margin: 20px;
    padding: 0;
    font-size: 32px;
    line-height: 1.2;
  }
  
  .right-text h3 {
    margin: 12px;
    font-size: 18px;
    padding: 0;
    line-height: 1.2;
  }
  
  .right-text button {
    background-color: #0d99ff; /* Set the button background color as needed */
    color: #fff; /* Set the button text color as needed */
    border: none;
    padding: 12px 24px;
    margin-top: 20px;
    cursor: pointer;
    font-size: 16px;
    transition: background-color 0.2s;
  }
  
  .right-text button:hover {
    background-color: #0056b3; /* Set the button hover background color as needed */
  }
  
  
  .right-content img {
    max-width: 100%;
    height: 350px;
    z-index: 1; 
    position: center;
    top: 44%;
    left: 60%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }  

  .hero-section {
    background-size: cover;
    background-position: center;
    height: 40rem; /* adjust the height to your desired size */
    display: flex;
    align-items: center;
  }

  .left-content {
    align-items: flex-start; /* Align the image to the left */
  }
  .hero-content {
    flex-direction: column;
    align-items: center;
  }

  .left-content img {
    position: absolute;
    top: 0;
    width: 500px;
    height: auto;
    transform: translateY(0%);
    z-index: 1; /* Make the left image appear below the right image */
  }
}

@media (max-width: 470px) {
  .right-text {
    max-width: 100%;
    width: 250px;
    height: 220px;
    position: center;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  
  .right-text h1 {
    padding: 0px !important;
    font-size: 24px;
    margin-bottom: 8px;
    line-height: 1.2;
    margin-top: 22px;
  }
  
  .right-text h3 {
    font-size: 16px;
    padding: 0 !important;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 4px;
  }
  
  .right-text button {
    background-color: #0d99ff; /* Set the button background color as needed */
    color: #fff; /* Set the button text color as needed */
    border: none;
    padding: 8px 12px;
    margin-top: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .right-text button:hover {
    background-color: #0056b3; /* Set the button hover background color as needed */
  }
  
  .right-content img {
    max-width: 100%;
    height: 235px;
    z-index: 1; 
    position: center;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }  

  .hero-section {
    background-size: cover;
    background-position: center;
    height: 25rem; /* adjust the height to your desired size */
    display: flex;
    align-items: center;
  }

  .left-content img {
    position: absolute;
    top: 0;
    width: 280px;
    height: auto;
    transform: translateY(0%);
    z-index: 1; /* Make the left image appear below the right image */
  }
}

@media (max-width: 400px) {
  .right-text {
    max-width: 100%;
    width: 250px;
    height: 220px;
    position: center;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
  }
  
  .right-text h1 {
    padding: 0px !important;
    font-size: 24px;
    margin-bottom: 8px;
    line-height: 1.2;
    margin-top: 22px;
  }
  
  .right-text h3 {
    font-size: 16px;
    padding: 0 !important;
    line-height: 1.2;
    margin-top: 0px;
    margin-bottom: 4px;
  }
  
  .right-text button {
    background-color: #0d99ff; /* Set the button background color as needed */
    color: #fff; /* Set the button text color as needed */
    border: none;
    padding: 8px 12px;
    margin-top: 8px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.2s;
  }
  
  .right-text button:hover {
    background-color: #0056b3; /* Set the button hover background color as needed */
  }
  
  .right-content img {
    max-width: 100%;
    height: 235px;
    z-index: 1; 
    position: center;
    top: 40%;
    left: 50%;
    transform: translateY(-50%) translateX(-50%);
    z-index: 2;
  }  

  .hero-section {
    background-size: cover;
    background-position: center;
    height: 25rem; /* adjust the height to your desired size */
    display: flex;
    align-items: center;
  }

  .left-content img {
    position: absolute;
    top: 0;
    width: 280px;
    height: auto;
    transform: translateY(0%);
    z-index: 1; /* Make the left image appear below the right image */
  }
}
