.tab-info {
    padding-bottom: 48px;

  }

  .title-frameworks {
    background-color: #200063;
    color: #fefefe;
    padding-bottom: 128px;
    padding-top: 128px;
    padding-left: 128px;
    padding-right: 128px;
    text-align: center;
  }

  @media (max-width: 1200px) { 
      .title-frameworks {
        padding-bottom: 96px;
        padding-top: 96px;
        padding-left: 128px;
        padding-right: 128px;
      }
  }
  
  @media (max-width: 750px) { 
    .title-frameworks {
      padding-bottom: 32px;
      padding-top: 32px;
      padding-left: 16px;
      padding-right: 16px;
    }
}