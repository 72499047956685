.terms-container {
    margin: 0 auto;
    padding-top: 128px;
    padding-right: 256px;
    padding-left: 256px;
    padding-bottom: 64px;
  }
   
  p {
    margin-bottom: 15px;
  }

  .t-and-c-title h1 {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 900px) {
    .terms-container {
        margin: 0 auto;
        padding-top: 90px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 32px;
      }

      .t-and-c-title h1 {
        font-size: 32px;
      }
    }
  