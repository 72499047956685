.framework-hero-container-sa {
  display: flex;
  align-items: flex-start;
  justify-content: left; /* Adjusted to align text to the left */
  padding-right: 256px;
  background-color: #200063;
  padding-top: 16px;
}

.framework-hero-content-sa {
  display: flex;
  justify-content: left;
  padding-bottom: 24px;
  padding-top: 32px;
  color: white;
}

.framework-hero-content-sa-body {
  color: white;
  padding-bottom: 64px;
}

.framework-hero-right-sa {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: left;
  align-items: flex-start;
  padding-left: 32px;
}

.framework-hero-left-sa,
.framework-hero-right-sa {
  flex: 1;
  text-align: left;
}

.framework-hero-image-sa {
  max-width: 100%;
  min-height: 500px;
  object-fit: cover;
}

@media only screen and (max-width: 1400px) {
  .framework-hero-container-sa {
    display: flex;
    padding-right: 32px;
  }

  .framework-hero-right-sa {
    padding-left: 32px;
  }

  .framework-hero-image-sa {
    max-width: 100%;
    min-height: 500px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 1250px) {
  .framework-hero-container-sa {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    padding-bottom: 0px;
  }

  .framework-hero-left-sa {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .framework-hero-image-sa {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
  }  

  .framework-hero-right-sa {
    order: -10;
    padding-left: 32px;
    padding-bottom: 0px;
  }

  .framework-hero-image-sa {
    max-width: 100%;
    min-height: 400px;
    object-fit: cover;
    justify-content: center;
  }

  .framework-hero-right-sa {
    padding-left: 32px;
  }

  .framework-hero-content-sa-body {
    color: white;
    padding-bottom: 32px;
  }
}

@media only screen and (max-width: 450px) {
  .framework-hero-container-sa {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .framework-hero-content-sa {
    color: white;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .framework-hero-content-sa-body {
    color: white;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .framework-hero-right-sa {
    order: -10;
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
  }

  .framework-hero-image-sa {
    max-width: 100%;
    min-height: 250px;
    object-fit: cover;
  }
}

@media only screen and (max-width: 300px) {
  .framework-hero-container-sa {
    display: flex;
    flex-direction: column;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 0px;
  }

  .framework-hero-content-sa h1 {
    font-size: 32px;
    padding-top: 32px;
  }

  .framework-hero-content-sa-body {
    color: white;
    padding-bottom: 32px;
  }

  .framework-hero-right-sa {
    order: -10;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 0px;
  }

  .framework-hero-image-sa {
    max-width: 250px;
    min-height: 150px;
    object-fit: cover;
  }
}
