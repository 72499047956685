.blog-post-page {
  padding: 20px;
  padding-left: 256px;
  padding-right: 256px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  padding-top: 94px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.blog-post-title {
  margin-bottom: 10px;
  text-align: center;
}

.blog-post-image {
  width: 100%;
  max-width: 600px;
  height: auto;
  object-fit: cover;
  margin-bottom: 20px;
}

.blog-post-paragraph {
  font-size: 16px;
  margin-bottom: 10px;
  text-align: justify;
}

.blog-post-link:hover {
  background-color: #0a58c3;
}

@media (max-width: 1200px) {
  .blog-post-page {
    padding-left: 128px;
    padding-right: 128px;
  }

  .blog-post-page h1 {
    font-size: 32px;
  }
}

@media (max-width: 700px) {
  .blog-post-page {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media (max-width: 450px) {
  .blog-post-page {
    padding-left: 24px;
    padding-right: 24px;
  }
}

@media (max-width: 350px) {
  .blog-post-page {
    padding-left: 12px;
    padding-right: 12px;
  }

  .blog-post-page h1 {
    font-size: 26px;
  }

  .blog-post-page h2 {
    font-size: 20px;
  }
}
