.my-profile-container {
    position: relative;
    padding-top: 100px;
    padding-bottom: 1rem;
    background-color: #200063;
  }

  .self-assessment{
    padding-top: 24px;
  }

  .result-box {
    border-radius: 24px;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    margin-bottom: 1rem;
    color: white;
    background-color: #0d99ff;
    padding-left: 24px;
    padding-right: 24px;
  }

  .result-box p {
    color: white;
  }

  .principle-results {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .principle-results {
    display: flex;
    border-radius: 24px;
  }

  .criteria-scores {
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
  }

  .criteria-scores {
    display: flex;
    margin-bottom: 1rem;
    border-radius: 24px;
  }
  
  .criteria-scores > div {
    flex: 1;
  }

  .criteria-score {
    background-color: #200063;
    border-radius: 12px;
    padding: 8px;
    margin-left: 4px;
    margin-right: 4px;
    min-width: 300px;
  }
  
  .principle-result {
    background-color: #F005A6;
    border-radius: 12px;
    padding: 8px;
    margin-left: 4px;
    margin-right: 4px;
    margin-top: 8px;
    min-width: 180px;
  }  

  .principle-result div {
    color: white;
  }  

  .profile-section {
    margin-bottom: 2rem;
    border-radius: 14px;
    color: white;
  }
  
  .close-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 21;
  }
  
  .close-icon-my-profile {
    position: fixed;
    top: -20px;
    right: 0px;
    font-size: 24px;
    cursor: pointer;
    background-color: transparent;
    border: none;
}

  .authenticator2 {
    position: fixed;
    z-index: 20;
    top: 130px;
    align-self: center;
    max-height: 100%;
    max-width: 100%;
    height: auto;
    left: 50%;
    transform: translateX(-50%);
  }
  
  .my-profile-container h2 {
    text-align: center;
  }
  
  .profile-sections {
    display: flex;
    justify-content: space-between;
  }
  
  .user-scores {
    margin-bottom: 2rem;
  }

  .centered-button-container {
    display: flex;
    justify-content: center;
    margin-top: 20px; /* Adjust the margin-top value as needed */
  }
  
  .profile-section h3 {
    margin-bottom: 1rem;
  }
  
  .profile-section p {
    font-size: 1.2rem;
  }

  .my-profile-container {
    padding-left: 200px;
    padding-right: 200px;
  }

  @media (max-width: 1650px) {
    .my-profile-container {
      padding-left: 100px;
      padding-right: 100px;
    }

    .criteria-scores {
      display: flex;
      flex-direction: row;
    }
  }

  @media (max-width: 1200px) {
    .principle-results {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .principle-result {
      flex-basis: calc(50% - 8px);
      margin: 4px;
    }

    .criteria-scores {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
  
    .criteria-score {
      flex-basis: calc(50% - 8px);
      margin: 4px;
    }
  }  
  
  @media (max-width: 900px) {
    .my-profile-container {
      padding-left: 16px;
      padding-right: 16px;
    }

    .result-box {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

  @media (max-width: 450px) {
    .my-profile-container {
      padding-left: 8px;
      padding-right: 8px;
    }
}

@media (max-width: 320px) {
  .my-profile-container {
    padding-left: 0px;
    padding-right: 0px;
  }

  .result-box {
    padding-left: 8px;
    padding-right: 8px;
  }

  .criteria-scores {
    margin: 0rem;
    border-radius: 24px;
  }

  .my-profile-container {
    position: absolute;
    padding-top: 100px;
  }

  .profile-section {
    width: 100%;
  }
}
@media (max-width: 425px) {
  .criteria-score {
    min-width: 100%;
  }
  
  .principle-result {
    min-width: 100%;
  }  
}