.accordion {
  max-width: 600px;
  margin: 2rem auto;
}

.accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  cursor: pointer;
  background-color: #f9f9f9;
}

.accordion-link {
  display: block;
  padding: 8px 52px;
  color: #333;
  text-decoration: none;
}

.accordion-link:hover {
  background-color: #eee;
}

.accordion-title:hover {
  background-color: #eee;
}

.accordion-title {
  padding: 1rem;
}

.accordion-content {
  padding-top: 0px;
  margin-bottom: 16px;
  padding-left: 20px;
}

.accordion-content {
  background-color: #f9f9f9;
}

.accordion-title div {
  display: flex;
  align-items: center;
  margin-right: 10px;
  padding-left: 10%;
}

.accordion-title div:first-child svg {
  margin-right: 10px;
}

@media screen and (max-width: 700px) {
  .accordion {
    width: 90%;
  }
}
