.three-products-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start; /* Align boxes at the top */
}

.animated-block {
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: 1;
  animation-name: slideInFromBottom;
  /* Additional styling for the animated block */
}

.feature-information {
  padding-bottom: 20px;
}
.three-product-full-container {
  padding-top: 32px;
  padding-left: 64px; /* Add left padding for desktop */
  padding-right: 94px; /* Add right padding for desktop */
}

.three-product-box {
  flex-basis: 33%; /* Adjust as needed */
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex-grow: 1;
  padding-left: 64px; /* Add left padding for tablet */
  align-self: stretch; /* Set equal height for all product boxes */
}

#three-product-box {
	font-size: 0;
}
#three-product-box img {
	width: 33.33%; 
	opacity: 0;
}

.three-product-box-text {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
}

.feature-picture-1 {
  width: 75%;
  max-height: 500px;
  object-fit: cover; /* Ensure the image covers the container */
}

.feature-picture-2 {
  width: 85%;
  max-height: 500px;
  object-fit: cover;
}

.feature-picture-3 {
  width: 85%;
  max-height: 500px;
  object-fit: cover;
}

.feature-title {
  margin-top: 0; /* Align title to top */
  margin-bottom: 16px; /* Add margin between title and body */
}

.feature-nav-link {
  margin-top: 0; /* Remove default margin */
  margin-top: -24px; /* Add margin between title and body */
}

@media screen and (max-width: 850px) {
  .three-products-container {
    flex-direction: column;
    align-items: center;
  }

  .three-product-box {
    padding-left: 32px;
    padding-right: 32px; /* Add left padding for tablet */
  }
  
  .three-product-full-container {
    padding-left: 16px; /* Add left padding for mobile */
    padding-right: 16px; /* Add right padding for mobile */
  }

  .feature-title {
    margin-top: 16px;
    margin-bottom: 16px; /* Add margin between title and body */
  }

  .feature-nav-link {
    margin-top: 0; /* Remove default margin */
    margin-top: -8px; /* Add margin between title and body */
  }

  .feature-picture-1 {
    width: 70%;
    max-height: 600px;
    object-fit: cover; /* Ensure the image covers the container */
  }
  
  .feature-picture-2 {
    width: 70%;
    max-height: 600px;
    object-fit: cover;
  }
  
  .feature-picture-3 {
    width: 70%;
    max-height: 600px;
  }

  .feature-picture-1,
  .feature-picture-2 {
    padding-bottom: 32px;
  }
}

@media screen and (max-width: 400px) {
  .three-product-box {
    padding-left: 16px;
    padding-right: 16px; /* Add left padding for tablet */
  }

  .feature-picture-1,
  .feature-picture-2 {
    width: 85%;
    max-height: 350px;
    padding-bottom: 32px;
  }

  .feature-picture-3 {
    width: 85%;
    max-height: 350px;
  }
}
