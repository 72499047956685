.faq-container {
  width: 90%;
  margin: auto;
  padding: 2rem;
  background-color: #200063;
  padding-top: 100px;
  border-radius: 10px;
  padding-bottom: 64px;
}

.faq-container h2 {
  color: #efefef;
  text-align: center;
  margin-bottom: 2rem;
}

.faq-items {
  display: flex;
  background-color: #200063;
  flex-wrap: wrap;
  justify-content: space-between;
}

.faq-item {
  width: 100%;
  background-color: #efefef;
  box-shadow: 0px 0px 8px #200063;
  padding: 1rem;
  margin-bottom: 8px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 768px) {
  .faq-item {
    width: 100%;
  }

  .faq-container {
    width: 100%;
    margin: 0px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #200063;
    padding-top: 100px;
    border-radius: 0px;
    padding-bottom: 0px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
  }
}

.faq-question {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.faq-question h3 {
  font-size: 1rem;
  margin: 0;
  flex: 1;
}

.arrow {
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 6px 6px 0 6px;
  border-color: #777 transparent transparent transparent;
  transition: all 0.3s ease-in-out;
}

.arrow.active {
  transform: rotate(180deg);
  border-color: #777 transparent;
}

.faq-answer {
  display: none;
  margin-top: 1rem;
  color: #555;
}

.faq-answer.active {
  display: block;
}
