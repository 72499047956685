.section-container {
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.section-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 16px;
    padding-left: 64px;
    padding-right: 64px;
    background-color: #200063;
    padding-bottom: 32px;
  }
  
  .frame {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100%;
    padding-top: 28px;
    padding-bottom: 28px;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 10px;
    color: #fff;
  }
  
  .frame h2 {
    margin-bottom: 32px;
    font-size: 42px;
  }
  
  .frame p {
    font-size: 18px;
    margin-bottom: 32px;
  }
  
  .frame a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    padding: 16px 16px;
    border-radius: 8px;
  }
  
  .frame a:hover {
    background-color: #fff;
    color: #000;
  }
  
  .frame-full {
    grid-row: 1 / span 2;
  }
  
  @media screen and (max-width: 750px) {
    .section-container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
    }
}

@media screen and (max-width: 400px) {
    .section-container {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto auto;
    }

    .frame {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      width: 100%;
      padding-top: 48px;
      padding-bottom: 48px;
      padding-left: 64px;
      padding-right: 64px;
      border-radius: 10px;
      color: #fff;
    }
}

@media screen and (max-width: 1450px) {
  .frame {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 10px;
  }

  .section-container {
    grid-gap: 16px;
    padding-left: 64px;
    padding-right: 64px;
    background-color: #200063;
  }
}

@media screen and (max-width: 1024px) {
  .frame {
    padding-top: 32px;
    padding-bottom: 32px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 10px;
  }

  .section-container {
    grid-gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #200063;
  }

  .frame h2 {
    margin-bottom: 8px;
    font-size: 32px;
  }
}