/* inspirationPage.css */

.inspiration-page {
    padding-top: 60px;
  }
  
  h1 {
    text-align: center;
    margin-bottom: 30px;
  }
  
  .purchase-links {
    display: flex;
    flex-direction: column;
    max-width: 260px;
    justify-content: center;
    text-align: center;
    margin: 0 auto; /* Add this line */
  }
  
  .book-list {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 20px;
    padding-left: 64px;
    padding-right: 64px;
    padding-bottom: 32px;
  }
  
  .book-item {
    background-color: #f0f0f0;
    padding: 20px;
    border-radius: 5px;
    display: flex;
    align-items: flex-start;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease;
  }
  
  .book-item:hover {
    transform: translateY(-3px);
  }
  
  .book-image {
    margin-right: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  
  .book-image img {
    object-fit: contain;
    border-radius: 5px;
  }  
  
  .book-details {
    flex-grow: 1;
  }
  
  .book-details h2 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .book-details p {
    font-size: 14px;
    margin-bottom: 20px;
  }
  
  .book-details a {
    display: inline-block;
    background-color: #007bff;
    color: #fff;
    padding: 8px 20px;
    border-radius: 5px;
    text-decoration: none;
    transition: background-color 0.2s ease;
  }
  
  .book-details a:hover {
    background-color: #0056b3;
  }
  
  .header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
  
  .header-text {
    margin-right: 20px;
    padding-left: 128px;
  }
  
  .header-text h1 {
    margin-bottom: 10px;
  }
  
  .header-text p {
    font-size: 16px;
    color: #888;
  }
  
  .header-image {
    width: 300px;
    height: auto;
  }
  
  .purchase-link {
    margin-bottom: 8px;
    margin-right: 8px;
    margin-left: 8px;
  }

  .book-info {
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    justify-content: space-between;
  }
  
  .book-image {
    height: 100%;
  }  

  .book-image,
  .book-text {
    width: 50%;
  }  

  .book-text {
    padding-left: 16px;
  }
  
  .book-text h2 {
    font-weight: bold;
  }

  @media (max-width: 1024px) {
    .book-list {
      grid-template-columns: repeat(2, 1fr);
      padding-left: 64px;
      padding-right: 64px;
    }

    .header-text {
        padding-left: 32px;
    }
  }
  
  @media (max-width: 750px) {
    .book-list {
      grid-template-columns: 1fr;
      padding-left: 8px;
      padding-right: 8px;
    }
  
    .book-item {
      width: 100%;
      align-items: center;
      text-align: center;
      padding-left: 8px;
      padding-right: 24px;
    }
  
    .book-image {
      margin-right: 0;
      margin-bottom: 10px;
    }

    .header-text {
        padding-left: 8px;
    }

    .header-text h1 {
        padding-top: 12px;
         font-size: 20px;
      }

      .header {
        margin-bottom: 0px;
      }
  }
  