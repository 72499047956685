.tab-feature-container {
  height: 100%;
  overflow: hidden;
}

.tab-feature-container.active {
  height: auto;
  transition: height 500ms;
}

.tabs-content-container {
  animation: fadeIn 2s;
  position: relative;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

.tab-feature-container.active .tabs-content-container {
  opacity: 1;
}


.tab-feature-container {
    align-items: center;
    height: 100%;
    width: 100%;
  }
  
  .tabs-header {
    text-align: center;
    padding-top: 16px;
  }
  
  .tab-btn {
    flex-grow: 1;
    cursor: pointer;
    padding: 1rem;
    border: none;
    outline: none;
    border-right: 1px solid #ccc;
    font-size: 1.1rem;
    transition: background-color 0.3s;
    white-space: nowrap;
  }
  
  .tab-btn:last-child {
    border-right: none;
  }
  
  .tab-btn:hover {
    opacity: 0.6;
    transition: opacity 0.3s ease-in-out;
  }
  
  .tab-btn.active {
    background-color: #fff;
    font-weight: bold;
    border-bottom: 2px solid #0d99ff;
    margin-bottom: -2px;
  }

  .tab-content {
    display: none;
  }

  .tab-content.active {
    display: block;
  }

  .tab-link-button {
    background-color: #0d99ff;
    color: #fff;
    border: none;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 20px;
  }
  
  .tab-link-button:hover {
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }

  .title-content {
    margin-bottom: 12px;
    margin-top: 16px;
  }

  .content-text-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
  }
  
  @media (min-width: 1441px) {
  .tabs-content-container {
    display: grid;
    grid-template-columns: 50% 50%;
    padding-left: 128px;
    padding-right: 256px;
    column-gap: 64px;
  }

  .content-image img{
    width: 100%;
    padding-top: 64px;
  }

  .content-text-container {
    padding-top: 32px;
  }
  }

  /* Responsive styles for mobile */

  @media (max-width: 1440px) {
    .tabs-content-container {
        display: grid;
        grid-template-columns: 50% 50%;
        padding-left: 64px;
        padding-right: 96px;
        column-gap: 32px;
      }
    
      .content-image img{
        width: 100%;
        padding-top: 64px;
      }
    
      .content-text-container {
        padding-top: 32px;
      }
  }

  @media (max-width: 1200px) {
    .tabs-content-container {
      grid-template-columns: 1fr;
    }
  
    .content-text-container {
      padding-top: 16px;
      padding-left: 32px;
      order: 1;
    }
  
    .content-image {
      order: 2;
      display: block;
      margin-left: 32px;
      margin-right: auto;
    }
  
    .content-image img {
      width: 100%;
      padding-top: 32px;
      padding-left: 16px;
      display: flex;
      justify-content: center;
    }
  }

  @media (max-width: 640px) {
    .tabs-content-container {
      padding-left: 8px;
      padding-right: 8px;
    }
  
    .content-text-container {
      padding-top: 0px;
      padding-left: 16px;
      padding-right: 16px;
      order: 1;
    }
  
    .content-image {
      order: 2;
      display: block;
      margin-left: 0px;
      margin-right: 0px;
    }
  
    .content-image img {
      width: 100%;
      padding-top: 16px;
      padding-left: 0px;
      display: flex;
      justify-content: center;
    }

    .tab-btn {
        flex-grow: 1;
        cursor: pointer;
        padding: 8px;
        border: none;
        outline: none;
        border-right: 1px solid #ccc;
        font-size: 14px;
        transition: background-color 0.3s;
      }

      .tabs-header {
        text-align: center;
        padding-top: 16px;
      }
  }