.fade-in-text {
  opacity: 0;
  animation: fade-in 1s ease-in-out forwards;
  transition: opacity 0.5s ease-in-out;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in-text.show {
  opacity: 1;
}

.hero-container {
  display: flex;
  color: white;
  padding-top: 64px;
  padding-bottom: 64px;
  background-color: #200063;
}

.hero-contents {
  display: flex;
  flex-direction: column;
  padding-top: 95px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.hero-content {
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;
  padding-left: 96px;
  padding-right: 96px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

/* For desktop screens */
@media screen and (min-width: 1025px) {
  .hero-contents {
    padding-left: 150px;
    padding-right: 150px;
  }

  .hero-content {
    padding-left: 32px;
    padding-right: 32px;
  }
}

/* For tablet screens */
@media screen and (max-width: 1024px) and (min-width: 641px) {
  .hero-contents {
    padding-left: 75px;
    padding-right: 75px;
  }

  .hero-content {
    padding-left: 32px;
    padding-right: 32px;
  }
}

/* For mobile screens */
@media screen and (max-width: 640px) {
  .hero-contents {
    padding-left: 24px;
    padding-right: 24px;
  }

  .hero-content {
    padding-left: 8px;
    padding-right: 8px;
  }
}
