.results-page {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #200063;
}

.hero-container-results {
  color: white;
  padding-top: 70px;
}

.share-box-title {
  color: white;
}

.results-page {
  padding-left: 200px;
  padding-right: 200px;
}

.orange-box-container {
  display: flex;
}

.orange-box-container > div {
  flex: 1;
}

.orange-box {
  background-color: #230fa9;
  color: white;
  padding: 8px;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.score {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.score-criteria {
  font-weight: bold;
}

.score-value {
  font-weight: bold;
}

.principles {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 0px;
  margin-top: 16px;
  color: white;
}

.principle-box {
  background-color: #F005A6;;
  border-radius: 5px;
  margin-top: 4px;
  padding-left: 8px;
  padding-right: 8px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.principle-name {
  font-weight: bold;
  margin-bottom: 0px;
}

.principle-score {
  font-weight: bold;
  margin-bottom: 0px;
}

.button-container {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.share-button,
.sign-in-button,
.peer-review-button {
  background-color: #0d99ff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  margin: 0 10px;
}

.share-box {
  text-align: center;
}

.social-media-icons {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.close-button {
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: #0d99ff;
  color: #fff;
}

.orange-box {
 margin-left: 10px;
 margin-right: 10px;
}

.blue-box {
  margin-left: 250px;
  margin-right: 250px;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
}

.results-title-description {
  margin-left: 128px;
  margin-right: 128px;
  margin-bottom: 16px;
}

.persona {
  margin-bottom: 16px;
}

.section-container {
  animation: slideIn 1s ease-in-out;
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(100px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}


.section-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 16px;
    padding-left: 64px;
    padding-right: 64px;
    background-color: #200063;
    padding-bottom: 32px;
  }

  .result-section-container {
    padding-left: 64px;
    padding-right: 64px;
  }
  
  .frame-result-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 64px;
    padding-right: 64px;
    border-radius: 10px;
    color: #fff;
  }
  
  .frame-result-page h2 {
    margin-bottom: 32px;
    font-size: 42px;
  }
  
  .frame-result-page p {
    font-size: 18px;
    margin-bottom: 32px;
  }
  
  .frame-result-page a {
    font-size: 16px;
    color: #fff;
    text-decoration: none;
    border: 2px solid #fff;
    padding: 16px 16px;
    border-radius: 8px;
  }
  
  .frame-result-page a:hover {
    background-color: #fff;
    color: #000;
  }
  
  .frame-full {
    grid-row: 1 / span 2;
  }

  .principle-box-name {
    display: flex;
  }

  @media screen and (max-width: 1650px) {
    .results-page {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media screen and (max-width: 1200px) {
  .results-page {
  padding-left: 36px;
  padding-right: 36px;
}
}

  @media screen and (max-width: 750px) {
    .section-container {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto auto auto;
    }

    .result-section-container {
      padding-left: 8px;
      padding-right: 8px;
    }

    .frame-result-page {
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 8px;
      padding-right: 8px;
      border-radius: 10px;
      color: #fff;
    }

    .results-page {
      padding-left: 8px;
      padding-right: 8px;
    }
    
    .orange-box-container {
      display: flex;
      flex-direction: column;
    }
}

@media screen and (max-width: 580px) {
  .principle-box {
    max-width: 400px;
  }
}

@media screen and (max-width: 400px) {
    .section-container {
      grid-template-columns: 1fr;
      grid-template-rows: auto auto auto auto auto auto;
    }

    .frame-result-page {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      text-align: center;
      height: 100%;
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
      padding-left: 64px;
      padding-right: 64px;
      border-radius: 10px;
      color: #fff;
    }
}

@media screen and (max-width: 1450px) {
  .frame-result-page {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 32px;
    padding-right: 32px;
    border-radius: 10px;
  }

  .section-container {
    grid-gap: 16px;
    padding-left: 64px;
    padding-right: 64px;
    background-color: #200063;
  }
}

@media screen and (max-width: 1024px) {
  .frame-result-page {
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 16px;
    padding-right: 16px;
    border-radius: 10px;
  }

  .hero-container-results h1 {
font-size: 32px;
  }

  .section-container {
    grid-gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    background-color: #200063;
  }

  .frame-result-page h2 {
    margin-bottom: 8px;
    font-size: 32px;
  }
}

