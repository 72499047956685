/* Licenses.css */

.licenses-container {
  margin: 0 auto;
  padding-top: 128px;
  padding-right: 256px;
  padding-left: 256px;
  padding-bottom: 64px;
}

p {
  margin-bottom: 16px;
}

@media only screen and (max-width: 900px) {
  .licenses-container {
      margin: 0 auto;
      padding-top: 90px;
      padding-right: 16px;
      padding-left: 16px;
      padding-bottom: 32px;
    }

    .licenses-page-title h1 {
      font-size: 32px;
    }
  }