.your-journey-container {
    width: 80%;
    margin: auto;
    background-color: #200063;
    padding-top: 140px;
    padding-bottom: 16px;
  }

  .journey-info {
    color: white;
  }

  .container-journey {
    background-color: #200063;
  }


  
  .your-journey-container h1 {
    text-align: center;
    color: #fff;
    margin-bottom: 16px;
  }
  
  .timeline {
    position: relative;
    margin: 50px 0;
    padding: 0;
    list-style-type: none;
  }
  
  .timeline:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%;
    width: 2px;
    margin-left: -1px;
    background-color: #fff;
  }
  
  .timeline-item {
    margin-bottom: 16px;
    position: relative;
    width: 50%;
    margin-left: auto;
    margin-right: auto;
  }
  
  .timeline-content {
    position: relative;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
    padding: 1rem;
  }  

  @media only screen and (max-width: 1024px) {
    .your-journey-container {
      width: 95%;
      margin: auto;
      padding-top: 120px;
      padding-bottom: 32px;
    }
  
    .container-journey {
      background-color: #200063;
    }

    .timeline-item {
      margin-bottom: 16px;
      width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    
    .timeline-content {
      position: relative;
      background-color: #fff;
      border-radius: 5px;
      box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.2);
      padding: 1rem;
    } 
  }