.close-icon-container {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 21;
  }
  
  .close-icon {
    position: fixed;
    top: 52px;
    right: 0px;
    font-size: 24px;
    cursor: pointer;
    background-color: transparent;
    border: none;
  }

  .authenticator-container2 {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 99;
  }