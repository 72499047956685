.framework-accordion {
  max-width: 600px;
  padding: 1rem;
}

.link-text {
  padding-top: 16px;
  font-size: 2rem;
}

.framework-accordion-title {
  display: flex;
  flex-direction: row;
  justify-content: flex-start; /* aligns items to the start (left) of the container */
  align-items: center; /* aligns items vertically in the center */
  cursor: pointer;
  background-color: #f9f9f9;
  width: 100%;
  padding-left: 256px; /* add padding here */
  padding-right: 256px; /* add padding here */
}

.framework-accordion-link {
  display: block;
  width: 100%; /* Set to take max width */
  color: #797979;
  text-decoration: none;
}

.framework-accordion-link:hover {
  opacity: 0.8;
}

.framework-icons {
  font-size: 24px;
  margin-right: 16px; /* Reduce right margin */
}

.framework-accordion-title:hover {
  background-color: #eee;
}

.framework-accordion-title,
.framework-accordion-content {
  padding-top: 2rem;
  padding-bottom: 1rem;
  background-color: #200063; /* Added margin-bottom between items */
}

.card-content {
  display: flex;
  flex-direction: column;
  max-height: 400px;
}

.link-text {
  padding-top: 16px;
}

@media screen and (max-width: 1250px) {
  .framework-accordion-title {
    padding-left: 128px; /* add padding here */
    padding-right: 128px; /* add padding here */
  }

.framework-accordion-content {
  padding-left: 32px; /* add padding here */
  padding-right: 32px; /* add padding here */
}
}

@media screen and (min-width: 1251px) {
.framework-accordion-content {
  padding-left: 64px; /* add padding here */
  padding-right: 64px; /* add padding here */
}
}

@media screen and (max-width: 700px) {
  .framework-accordion-title {
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 24px;
    padding-bottom: 8px;
  }

.framework-accordion-content {
  padding-left: 16px; /* add padding here */
  padding-right: 16px; /* add padding here */
}
}
