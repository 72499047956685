.image-right-text-left-container-bottom {
  display: flex;
  align-items: center;
}

.body-image-right {
  padding-left: 128px;
  padding-right: 128px;
  padding-top: 16px;
  padding-bottom: 64px;
}

.image-right-text-left-content-bottom {
  padding-left: 128px;
}

.image-right-text-left-content-bottom h1 {
  font-size: 102px;
}

.image-right-text-left-left-bottom,
.image-right-text-left-right-bottom {
  flex: 1;
}

.image-right-text-left-image-bottom {
  max-width: 100%;
  min-height: 400px;
  object-fit: cover;
  align-self: flex-start;
  padding-right: 120px;
}

@media only screen and (max-width: 1600px) {
  .image-right-text-left-image-bottom {
    min-height: 400px;
    padding-right: 100px;
  }

  .image-right-text-left-container-bottom {
    padding-left: 128px;
  }

  .body-image-right {
    padding-left: 128px;
    padding-right: 128px;
  }

  .image-right-text-left-content-bottom {
    padding-left: 64px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 102px;
  }
}

@media only screen and (max-width: 1400px) {
  .image-right-text-left-container-bottom {
    padding-left: 32px;
  }

  .image-right-text-left-image-bottom {
    min-height: 300px;
    padding-right: 64px;
  }

  .image-right-text-left-container-bottom {
    padding-left: 32px;
  }

  .body-image-right {
    padding-left: 96px;
    padding-right: 96px;
  }

  .image-right-text-left-content-bottom {
    padding-left: 64px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 82px;
  }
}

@media only screen and (max-width: 950px) {
  .image-right-text-left-container-bottom {
    padding-left: 32px;
  }

  .image-right-text-left-image-bottom {
    min-height: 400px;
    padding-right: 16px;
  }

  .image-right-text-left-container-bottom {
    padding-left: 0px;
  }

  .body-image-right {
    padding-left: 64px;
    padding-right: 64px;
  }

  .image-right-text-left-content-bottom {
    padding-left: 0px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 82px;
  }

  .image-right-text-left-container-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 62px;
  }

  .image-right-text-left-right-bottom {
    order: 0;
  }

  .image-right-text-left-left-bottom {
    padding-right: 0px;
    padding-top:32px;
  }

  .image-right-text-left-image-bottom {
    max-width: 100%;
    min-height: 300px;
  }
}

@media only screen and (max-width: 700px) {
  .image-right-text-left-container-bottom {
    padding-left: 0px;
  }

  .image-right-text-left-image-bottom {
    min-height: 300px;
    padding-right: 0px;
  }

  .image-right-text-left-container-bottom {
    padding-left: 16px;
    padding-right: 16px;
  }

  .body-image-right {
    padding-left: 32px;
    padding-right: 32px;
  }

  .image-right-text-left-content-bottom {
    padding-left: 16px;
    padding-right: 16px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 48px;
  }

  .image-right-text-left-container-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-right-bottom {
    order: 0;
  }

  .image-right-text-left-left-bottom {
    padding-right: 1px;
  }

  .image-right-text-left-image-bottom {
    max-width: 100%;
    min-height: 350px;
  }
}

@media only screen and (max-width: 500px) {
  .image-right-text-left-container-bottom {
    padding-left: 0px;
  }

  .image-right-text-left-image-bottom {
    min-height: 150px;
    padding-right: 0px;
  }

  .image-right-text-left-container-bottom {
    padding-left: 16px;
    padding-right: 16px;
  }

  .body-image-right {
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 64px;
  }

  .image-right-text-left-content-bottom {
    padding-left: 24px;
    padding-right: 24px;
  }

  .image-right-text-left-content-bottom h1 {
    font-size: 32px;
  }

  .image-right-text-left-container-bottom {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-left: 0px;
  }

  .image-right-text-left-right-bottom {
    order: 0;
    padding-left: 16px;
  }

  .image-right-text-left-left-bottom {
    padding-right: 1px;
  }
}
