.framework-hero-container-pr {
  padding-top: 90px;
  display: flex;
  align-items: left; 
  padding-right: 256px;
  background-color: white;
  color: black;
}

.peer-review-button:hover {
  opacity: 80%;
}

.peer-review-button {
  font-size: 16px;
  background-color: #0D99FF;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}
.button-pr-container {  
  display: flex;
  align-items: center;
  align-self: center;
  padding-bottom: 16px;
}

.framework-hero-content-pr {
  display: flex;
  align-items: center;
  padding-bottom: 16px;
  justify-content: flex-start;
  text-align: left;
}

.framework-hero-right-pr {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding-left: 32px;
  color: black;
}

.framework-hero-left-pr,
.framework-hero-right-pr {
  flex: 1;
  justify-content: center;
  text-align: left;
}

.framework-hero-image-pr {
  max-width: 100%;
  min-height: 450px; 
  object-fit: cover; 
  opacity: 100%;
}

@media only screen and (max-width: 1400px) {
  .framework-hero-container-pr {
    padding-top: 90px;
    display: flex;
    align-items: center; 
    padding-right: 64px;
  }

  .framework-hero-image-pr {
    max-width: 100%;
    min-height: 400px; 
    object-fit: cover; 
  }
}

@media only screen and (max-width: 1250px) {
  .framework-hero-container-pr {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-right: 0px;
  }

  .framework-hero-right-pr {
    order: -10;
    padding-left: 64px;
    padding-bottom: 0px;
    padding-right: 64px;
  }

  .framework-hero-image-pr {
    max-width: 100%;
    min-height: 400px; 
    object-fit: cover; 
  }
}

@media only screen and (max-width: 450px) {
  .framework-hero-container-pr {
    padding-top: 90px;
    display: flex;
    flex-direction: column;
    align-items: center; 
    padding-right: 0px;
  }

  .framework-hero-content-pr {
    display: flex;
    align-items: center;
    padding-bottom: 24px;
    justify-content: flex-start;
    text-align: left;
  }

  .framework-hero-content-pr h1 {
    padding-bottom: 0px;
    margin-bottom: 0px;
    font-size: 32px;
  }

  .framework-hero-right-pr {
    order: -10;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 0px;
  }

  .framework-hero-image-pr {
    max-width: 100%;
    min-height: 250px; 
    object-fit: cover; 
  }
}