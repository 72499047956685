.privacy-container {
    margin: 0 auto;
    padding-top: 128px;
    padding-right: 256px;
    padding-left: 256px;
    padding-bottom: 64px;
  }
  
  p {
    margin-bottom: 15px;
  }
  
  .privacy-container ul {
    margin-bottom: 15px;
    padding-left: 20px;
  }
  
  li {
    margin-bottom: 5px;
  }

  .privacy-policy-title h1 {
    margin-bottom: 0px;
  }

  @media only screen and (max-width: 900px) {
    .privacy-container {
        margin: 0 auto;
        padding-top: 90px;
        padding-right: 16px;
        padding-left: 16px;
        padding-bottom: 32px;
      }

      .privacy-policy-title h1 {
        margin-bottom: 0px;
        font-size: 32px;
      }
    }
  
  