.share-box {
    margin-bottom: 20px;
  }
  
  .social-media-icons {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    height: 60px;
  }
  