.all-frames-page {
  display: flex;
  flex-wrap: wrap; /* Allow flex items to wrap to the next row */
  justify-content: center; /* Center items horizontally */
  padding: 20px;
  padding-top: 24px;
  background-color: #200063;
}

.all-frames-container {
  padding-top: 96px;
  background-color: #200063;
}

.frame-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  background-color: #f1f1f1;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 600px;
}

.frame-section img {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  border-radius: 8px;
}

.frame-details {
  text-align: center;
  margin-top: 10px;
}

.frame-details h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.frame-details p {
  font-size: 16px;
  margin-bottom: 10px;
}

.frame-details a {
  color: #007bff;
  text-decoration: none;
}

/* Responsive styles */
@media (min-width: 769px) {
  .frame-section {
    width: 45%; /* Display frames in two columns on desktop */
    margin-right: 20px;
    margin-left: 20px;
  }
}

@media (max-width: 768px) {
  .frame-section {
    max-width: 400px;
  }
}

@media (max-width: 480px) {
  .frame-section {
    max-width: 300px;
  }
}

