.likert-questionnaire-form {
  display: flex;
  flex-direction: column;
  padding-top: 96px;
  padding-left: 32px; /* Updated padding value */
  padding-right: 32px; /* Updated padding value */
  max-width: 100%;
  background-color: white;
  align-content: center;
  align-items: center;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
}

.likert-submit-button {
  margin-top: 16px;
}

.comment-peer-review {
  padding: 8px;
}

.comment-peer-review {
  border: 1px solid black;
  border-radius: 8px;
  width: 400px;
  height: 100px;
}

.reviewer-peer-review {
  border: 1px solid black;
  display: flex;
  justify-content: center;
  text-align: center;
  justify-items: center;
  border-radius: 8px;
  width: 200px;
  height: 30px;
}

.reviewer-text {
  padding-bottom: 8px;
}

.intro-page {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding-top: 16px;
}

.intro-image {
  max-width: 300px;
  margin-bottom: 20px;
}

.intro-title {
margin-bottom: 32px;
padding-top: 64px;
}

.intro-text {
font-size: 18px;
margin-bottom: 20px;
padding-left: 128px;
padding-right: 128px;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 10px;
  margin-top: 20px;
  padding-bottom: 64px;
}

.likert-submit-button {
  background-color: #0d99ff;
  color: white;
  border: none;
  padding: 12px 24px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
}

.likert-submit-button:hover {
  opacity: 90%;
}

.likert-submit-button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.continue-button,
.start-new-button {
  padding: 10px 20px;
  font-size: 16px;
  background-color: #0d99ff;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.continue-button:hover,
.start-new-button:hover {
  opacity: 90%;
}

.close-button {
  background-color: #0d99ff;
  color: #fff;
}

.likert-form {
  max-width: 1200px;
  width: 100%;
  padding: 32px;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.1);
}

.likert-title {
  text-align: center;
  margin-bottom: 8px;
}

.likert-body {
  text-align: center;
  margin-bottom: 30px;
}

.questionContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 500px;
  margin-top: 8px;
  margin-bottom: 32px; /* Updated margin-bottom value */
  opacity: 0.2; /* Added opacity for inactive questions */
}

.questionName {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.questionContainer.active,
.questionContainer.answered,
.questionContainer.answered + .questionContainer {
  opacity: 1; /* Full opacity for active, answered, and next unanswered questions */
}

.selected {
  background-color: #333;
  color: #fff;
}

.questionTextRight {
  width: 25%;
  text-align: center;
  font-size: 20px;
  padding-right: 32px;
  padding-left: 32px;
  color: #05A6F0;
}

.questionTextLeft {
  width: 25%;
  text-align: center;
  font-size: 20px;
  padding-right: 32px;
  padding-left: 32px;
  color: #F005A6;
}

.likertScale {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 70%;
}

.likert-button {
  display: flex;
  margin-left: 4px;
  margin-right: 4px;
  align-items: center;
  justify-content: center;
  position: relative;
  border: 1px solid
}

.likert-button input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.checkmark {
  width: 70%;
  height: 70%;
  background-color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: background-color 0.3s ease;
}

.likert-button-1 input:checked ~ .checkmark {
  background-color: #F005A6;
  color: #fff;
  height: 60px;
  width: 60px;
}

.likert-button-1 {
  height: 60px;
  width: 60px;
  border: 5px solid #F005A6;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.likert-button-2 input:checked ~ .checkmark {
  background-color: #F005A6;
  color: #fff;
  height: 50px;
  width: 50px;
}

.likert-button-2 {
  height: 50px;
  width: 50px;
  border: 5px solid #F005A6;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.likert-button-3 input:checked ~ .checkmark {
  background-color: #A6F005;
  color: #fff;
  height: 40px;
  width: 40px;
}

.likert-button-3 {
  height: 40px;
  width: 40px;
  border: 5px solid #A6F005;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.likert-button-4 input:checked ~ .checkmark {
  background-color: #05A6F0;
  color: #fff;
  height: 50px;
  width: 50px;
}

.likert-button-4 {
  height: 50px;
  width: 50px;
  border: 5px solid #05A6F0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.likert-button-5 input:checked ~ .checkmark {
  background-color: #05A6F0;
  height: 60px;
  width: 60px;
}

.likert-button-5 {
  height: 60px;
  width: 60px;
  border: 5px solid #05A6F0;
  border-radius: 50%;
  cursor: pointer;
  transition: all 0.3s ease;
}

.likert-button-1:hover,
.likert-button-5:hover {
  box-shadow: 0px 0px 10px #333;
}

.likert-button-2:hover,
.likert-button-4:hover {
  box-shadow: 0px 0px 10px #333;
}

.likert-button-3:hover {
  box-shadow: 0px 0px 10px #333;
}

.submit-button {
  display: inline-block;
  background-color: #333;
  color: #fff;
  padding: 12px 24px;
  font-size: 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #555;
}

@media (max-width: 1200px) {
  .intro-text {
    padding-left: 64px;
    padding-right: 64px;
  }
  .intro-title {
    padding-left: 64px;
    padding-right: 64px;
  }
}

@media (max-width: 620px) {
  .intro-page h1 {
    font-size: 32px !important;
  }

  .intro-title {
    padding-left: 16px;
    padding-right: 16px;
  }

  .likert-form h1 {
    font-size: 32px !important;
  }

  .intro-text {
    font-size: 14px;
    padding-left: 32px;
    padding-right: 32px;
  }

  .intro-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .likert-questionnaire-form {
    padding-top: 60px;
    padding-left: 0px; /* Updated padding value */
    padding-right: 0px; /* Updated padding value */
  }

    .likert-button {
      margin-left: 2px;
      margin-right: 2px;
  }

  .questionContainer {
    width: 100%;
    padding-left: 0px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .likert-form {
    max-width: 400px;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: none;
  }

  .questionTextLeft
   {
    padding-left: 0px;
    padding-right: 0px;
    width: 17%;
    font-size: 12px;
  }

  .questionTextRight
   {
    padding-right: 8px;
    padding-left: 0px;
    width: 15%;
    font-size: 12px;
  }

  .likertScale {
    width: 70%;
  }

  .likert-button-1,
  .likert-button-5 {
    height: 50px;
    width: 50px;
  }

  .likert-button-5 input:checked ~ .checkmark,
  .likert-button-1 input:checked ~ .checkmark {
    height: 50px;
    width: 50px;
  }

  .likert-button-2,
  .likert-button-4 {
    height: 40px;
    width: 40px;
  }

  .likert-button-2 input:checked ~ .checkmark,
  .likert-button-4 input:checked ~ .checkmark {
    height: 40px;
    width: 40px;
  }

  .likert-button-3 {
    height: 35px;
    width: 35px;
  }

  .likert-button-3 input:checked ~ .checkmark {
    height: 35px;
    width: 35px;
  }

  .likert-button-1:hover,
  .likert-button-5:hover {
    box-shadow: none;
  }
  
  .likert-button-2:hover,
  .likert-button-4:hover {
    box-shadow: none;
  }
  
  .likert-button-3:hover {
    box-shadow: none;
  }
}

@media (max-width: 450px) {
  .comment-peer-review {
    border: 1px solid black;
    border-radius: 8px;
    width: 250px;
    height: 60px;
  }

  .intro-page h1 {
    font-size: 32px !important;
  }

  .likert-form h1 {
    font-size: 32px !important;
  }

  .intro-text {
    font-size: 14px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .intro-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .likert-questionnaire-form {
    padding-top: 60px;
    padding-left: 0px; /* Updated padding value */
    padding-right: 0px; /* Updated padding value */
  }

    .likert-button {
      margin-left: 2px;
      margin-right: 2px;
  }

  .questionContainer {
    width: 100%;
    padding-left: 0px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .likert-form {
    max-width: 330px;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: none;
  }

  .questionTextLeft
   {
    padding-left: 0px;
    padding-right: 0px;
    width: 17%;
    font-size: 12px;
  }

  .questionTextRight
   {
    padding-right: 8px;
    padding-left: 0px;
    width: 15%;
    font-size: 12px;
  }

  .likertScale {
    width: 65%;
  }

  .likert-button-1,
  .likert-button-5 {
    height: 40px;
    width: 40px;
  }

  .likert-button-5 input:checked ~ .checkmark,
  .likert-button-1 input:checked ~ .checkmark {
    height: 40px;
    width: 40px;
  }

  .likert-button-2,
  .likert-button-4 {
    height: 35px;
    width: 35px;
  }

  .likert-button-2 input:checked ~ .checkmark,
  .likert-button-4 input:checked ~ .checkmark {
    height: 35px;
    width: 35px;
  }

  .likert-button-3 {
    height: 30px;
    width: 30px;
  }

  .likert-button-3 input:checked ~ .checkmark {
    height: 30px;
    width: 30px;
  }

  .likert-button-1:hover,
  .likert-button-5:hover {
    box-shadow: none;
  }
  
  .likert-button-2:hover,
  .likert-button-4:hover {
    box-shadow: none;
  }
  
  .likert-button-3:hover {
    box-shadow: none;
  }
}

@media (max-width: 350px) {
  .intro-page h1 {
    font-size: 32px !important;
  }

  .likert-form h1 {
    font-size: 32px !important;
  }

  .intro-text {
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
  }

  .intro-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  .likert-questionnaire-form {
    padding-top: 60px;
    padding-left: 0px; /* Updated padding value */
    padding-right: 0px; /* Updated padding value */
  }

    .likert-button {
      margin-left: 2px;
      margin-right: 2px;
  }

  .questionContainer {
    width: 100%;
    padding-left: 0px;
    margin-top: 8px;
    margin-bottom: 32px;
  }

  .likert-form {
    max-width: 270px;
    width: 100%;
    padding-top: 32px;
    padding-bottom: 32px;
    padding-right: 0px;
    padding-left: 0px;
    box-shadow: none;
  }

  .questionTextLeft
   {
    padding-left: 0px;
    padding-right: 0px;
    width: 17%;
    font-size: 12px;
  }

  .questionTextRight
   {
    padding-right: 8px;
    padding-left: 0px;
    width: 15%;
    font-size: 12px;
  }

  .likertScale {
    width: 65%;
  }

  .likert-button-1,
  .likert-button-5 {
    height: 35px;
    width: 35px;
  }

  .likert-button-5 input:checked ~ .checkmark,
  .likert-button-1 input:checked ~ .checkmark {
    height: 35px;
    width: 35px;
  }

  .likert-button-2,
  .likert-button-4 {
    height: 30px;
    width: 30px;
  }

  .likert-button-2 input:checked ~ .checkmark,
  .likert-button-4 input:checked ~ .checkmark {
    height: 30px;
    width: 30px;
  }

  .likert-button-3 {
    height: 25px;
    width: 25px;
  }

  .likert-button-3 input:checked ~ .checkmark {
    height: 25px;
    width: 25px;
  }

  .likert-button-1:hover,
  .likert-button-5:hover {
    box-shadow: none;
  }
  
  .likert-button-2:hover,
  .likert-button-4:hover {
    box-shadow: none;
  }
  
  .likert-button-3:hover {
    box-shadow: none;
  }
}
