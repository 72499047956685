.footer-container {
  display: grid;
  background-color: #200063;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: 1fr;
  padding-bottom: 64px;
  max-width: 100%;
}

.footer-details h5 {
  font-weight: bold;
}

.footer-container {
  display: flex;
  justify-content: space-between;
  padding-left: 256px;
  padding-right: 256px;
}

.footer-details {
  color: white;
  text-decoration: none;
}

.footer-details h5 {
  color: white;
  margin-bottom: 8px;
  font-size: 16px;
}

.footer-details ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-details ul li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease-in-out;
}

.footer-details ul li a:hover {
  color: #ffffff80;
}

.footer-box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.footer-box ul li a {
  color: white;
  transition: color 0.3s ease-in-out;
}

.footer-box ul li a:hover {
  color: #ffffff80;
}

.footer-details ul li a,
.footer-box ul li a {
  text-decoration: none;
  color: white;
  transition: color 0.3s ease-in-out;
  cursor: pointer; /* Add this line */
  font-size: 14px;
}

.footer-details ul li a:hover,
.footer-box ul li a:hover {
  color: #ffffff80;
}


@media only screen and (max-width: 1200px) {
  .footer-container {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    padding-left: 128px;
    padding-right: 128px;
    grid-auto-rows: 60px;
    grid-gap: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .footer-container {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: repeat(4, 1fr);
    padding-left: 64px;
    padding-top: 16px;
    padding-right: 32px;
  }
}
