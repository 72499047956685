.authenticator-container {
  position: fixed;
  top: 30%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.authenticator {
  position: relative;
  z-index: 20;
  top: 70px;
  align-self: center;
  max-height: 100%;
  max-width: 100%;
  height: auto;
  left: 50%;
  transform: translateX(-50%);
}

.close-icon-container {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 21;
}

.close-icon {
  position: fixed;
  top: 70px;
  right: 0px;
  font-size: 24px;
  cursor: pointer;
  background-color: transparent;
  border: none;
}

.nav-container {
  display: flex;
  position: fixed;
  background-color: #200063;
  height: 60px;
  width: 100%;
  flex: 100%;
}

.hamburger-menu-logged-in {
  display: flex;
  padding-top: 8px;
  justify-content: center;
}

.hamburger-menu-logged-out {
  display: flex;
  padding-top: 8px;
  justify-content: center;
}

.navbar-nav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  list-style: none;
  margin: 0px;
  padding: 0px;
}


.nav-left {
  flex: 1 0 20%;
  display: flex;
  height: 60px;
  justify-content: left;
  align-items: center;
  padding-left: 128px;
}

.nav-image {
  height: 50px;
  cursor: pointer;
}

.nav-center {
  flex: 1 0 50%;
  display: flex;
  justify-content: left;
  align-items: center;
}

.nav-link {
  justify-content: space-between;
  font-family: sans-serif;
  font-size: 16px;
  margin-right: 32px;
  text-decoration: none;
}

.nav-button {
  margin-left: 1rem;
  color: white;
}

.nav-button:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}

.nav-right {
  flex: 1 0 30%;
  display: flex;
  justify-content: right;
  padding-right: 128px;
  align-items: center;
}

.navbar-wrapper {
  position: relative;
  z-index: 30;
}

.nav-container {
  display: flex;
  height: 60px;
  flex: 100%;
}

.hamburger-btn {
  display: none;
  position: absolute;
  top: 10px;
  right: 16px;
  width: 40px;
  height: 36px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  z-index: 10;
}

.hamburger-icon {
  display: block;
  position: relative;
  width: 30px;
  height: 30px;
  transition: transform 0.2s ease-out;
}

.hamburger-icon:before,
.hamburger-icon:after {
  content: '';
  display: block;
  position: absolute;
  width: 20px;
  height: 20px;
  transition: transform 0.2s ease-out;
}

.hamburger-icon:before {
  transform: translateY(-6px);
}

.hamburger-icon:after {
  transform: translateY(4px);
}

.hamburger-menu {
  display: none;
  position: fixed;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(248, 248, 248);
  padding: 16px;
  box-sizing: border-box;
}

.show {
  display: block;
}

.hamburger-link {
  display: block;
  font-size: 16px;
  color: rgb(216, 216, 216);
  margin-bottom: 16px;
  margin-left: 64px;
  text-decoration: none;
}

.hamburger-sublink {
  display: block;
  font-size: 16px;
  color: rgb(216, 216, 216);
  margin-bottom: 16px;
  margin-left: 64px;
  text-decoration: none;
}

.nav-image:hover {
  opacity: 0.8;
  transition: opacity 0.3s ease-in-out;
}


@media screen and (max-width: 1450px) {
  .nav-center,
  .nav-right {
    display: none;
  }

  .nav-left {
    flex: 1 0 20%;
    display: flex;
    height: 60px;
    padding-left: 64px;
    justify-content: left;
    align-items: center;
  }

  .hamburger-btn {
    display: block;
    color: rgb(233, 233, 233);
  }

  .nav-link {
    display: block;
    font-size: 16px;
    color: rgb(255, 255, 255);
    margin-right: 0;
    text-decoration: none;
  }

  .nav-link:hover {
    opacity: 0.8;
    transition: opacity 0.3s ease-in-out;
  }
}

@media screen and (max-width: 800px) {
  .nav-image {
    height: 40px;
  }

  .nav-left {
    padding-left: 24px;
  }
}

@media screen and (max-width: 530px) {
  .authenticator-container {
    position: fixed;
    top: 37%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
